 $primary: #002244; /* MAIN COLOR */
$secondary: #FB4F14; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


a, p, ol, ul {
font-family: 'Belgrano', serif !important;
}

h1, h2, h3, h4 {
font-family: 'Lemon', cursive !important;
}

.download2 .action h2 {
    margin-top: 25px;
}

.download2 {
    background-image: url('../img/bg2.jpg');
    background-attachment: scroll;
    background-position: 40% 0%;

        @media (max-width: 1024px) {
            background-attachment: initial !important;
            background-position: 40% 10%;
        }
        @media (max-width: 767px) {
                background-position: 31% 50%;
            }
}

.navbar-default {width: 100% !important;}


.download3 {
    background: $primary;
	height: auto;
}

// .action {padding: 50px 0px;}


// .download-info {
//   margin-top: 150px;
// }



.navbar-toggle {
        margin-top: 18px;
}



html, body {
	width: 100%;
	overflow-x: hidden;
}
// body {
// 	height:100%;
// 	font-weight: 600;
// 	padding:0;
// 	margin:0;
// 	font-size:16px;
// 	line-height: 28px;
// 	color: #777;
// 	background: $wht;
// 	overflow-x:hidden;
// 	position: relative;
// }
h1 { font-size: 3em; line-height:1.2em; margin:0 0 .3em; }
h2 { font-size: 1.8em; line-height:1.4em; margin:0 0 .3em;  }
h3 { font-size: 1.4em; line-height:1.4em; }
h4 { font-size: 1.25em; line-height:1.4em;}
h5 { font-size: 1.1em; line-height:1.4em; }
h6 { font-size: 1em; line-height:1.2em; }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	text-decoration: none
}
p {
	padding: 0 0 1em;
	margin:0;
}
a {
	color: #201a1c;
	outline: none;
	font-weight: bold;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
a:hover {
	text-decoration: none;
	color: #1f2222;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
.highlight {
	color: #201a1c;
}
#intro p, footer p {
  font-size: 1em !important;
  font-weight: 400;
}
p {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.5em;
}
.intro p {
  font-size: 1em;
}

.shadow {
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#preloader {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	overflow: visible;
	background: $wht url(../images/loading.gif) no-repeat center center;
}


.parallax {background-image:url('../img/bg.jpg');
			 background-repeat: no-repeat;}


.fullscreen {
    width:100%;
    min-height:100%;
	background-repeat:no-repeat;
}

@media (max-width: 1024px) {

}
.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    padding: 44px 0px;
}
.overlay2 {
    background-color: rgba(0, 0, 0, 0.65);
    position: relative;
    width: 100%;
    display: block;
    padding: 150px 0px;

}
#menu {
	z-index: 1000 !important;
    width: 100% !important;
}

.navbar-nav {
/*    height: 56px;*/
    margin-top: 12px;
}


.navbar-default {
	    background: $primary;
	    box-shadow: 0px 0px 15px #000;
		z-index: 5000;
}
.navbar-default .navbar-brand {
    padding: 7px;
}
.navbar-default .navbar-nav > li > a {
    color: $wht;
	font-weight: 500;
    outline: none;
}

.navbar-default .navbar-nav>li>a:focus, {
	color: $wht;
}
.navbar-default .navbar-nav>li>a:hover {
	color: $secondary;
} 

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
		outline: none;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		outline: none !important;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}
.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input:-webkit-autofill {
	background-color: #fff !important;
}

input#username {
	margin-bottom: 20px;
	background-color: #fff !important;
}

.modal {
    overflow-y: hidden;
}

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 25px 20px;
	background: $secondary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}


.modal-open {
  overflow: hidden !important; 
  padding-left: 0px !important;
}

/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: $secondary;
    border-color: $wht;
	   color: $wht;
padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: $primary;
    color: $wht;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}


/* ========== HEADER ============== */


// .btn-default {
// 	font-size: 17px;
// 	margin-right: 10px;
// 	line-height: 20px;
// 	height: 50px;
// 	border: 2px solid #201a1c;
// 	background: transparent;
// 	transition: all 0.4s;
// 	color: #201a1c;
// 	border-radius: 100px;
// }
// .btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
// 	border: 2px solid #201a1c;
// 	background: #201a1c;
// 	color: white;
// }
// .btn-primary {
// 	font-size: 17px;
// 	margin: 30px 0 10px;
// 	margin-right: 10px;
// 	line-height: 20px;
// 	height: 50px;
// 	border: 2px solid #c72127;
// 	background: #c72127;
// 	transition: all 0.4s;
// 	color: white;
// 	border-radius: 100px;
// }
// .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
// 	border: 2px solid #201a1c;
// 	background: transparent;
// 	color: #201a1c;
// }
// .btn-secondary {
// 	font-size: 20px;
// 	font-weight: 300;
// 	line-height: 20px;
// 	padding: 20px 18px;
// 	height: 65px;
// 	border: none;
// 	background: #c72127;
// 	transition: all 0.4s;
// 	color: $wht;
// 	border-radius: 4px;
// }
// .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .open > .dropdown-toggle.btn-secondary {
// 	background: $wht;
// 	color: #380004;
// }





.site-name img {

}
.logo {
	margin: 300px 0 40px 0;


    @media (max-width: 1024px) {
        margin: 50px 0 40px 0;
    }

}
.logo img{
}
.landing h1{
	font-size: 56px;
	font-weight: 300;
	color: $wht;
	margin: 30px 0 30px 0;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
}
.landing p, .landing h2 {
	color: $wht;
}
.landing-text {
	margin-bottom: 20px;
}
.landing-text p {
	font-size: 17px !important;
}
.head-btn {
	margin-bottom: 150px;
}
.option {
	text-transform: uppercase;
	padding: 5px;
	min-width: 80px;
	margin-right: 5px;
	transition: all 0.4s;
	font-size: 14px;
	color: $wht;
}
.signup-header {
	margin: 150px 0 100px;
	background: rgba(255,255,255,0.2);
	border-radius: 4px;
	padding-left: 20px;
	padding-right: 20px;
    padding-bottom: 30px;
}
.signup-header h3{
	padding: 20px 0 10px;
	color: $wht;
	font-weight: 300;
}
.form-header input {
	position: relative;
	padding: 5px 15px;
}
.form-header .form-control {
	border-radius: 0;
	border: solid 1px #dadada;
	background-color: $wht;
	color: #333;
	height: 55px;
}
.form-header .btn {
	border-radius: 0;
	height: 55px;
	width: 100%;
	background-color: #201a1c;
	color: $wht;
	font-size: 17px !important;
	padding: 0 33px;
	border: none;
	margin: 0;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
.form-header .btn:hover {
	background-color: #1f96e0;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
.privacy {
	padding-top: 0;
	font-size: 13px;
}
.signup-header p {
	color: $wht;
}
.privacy a {
	text-decoration: underline;
	color: $wht;
	font-weight: 300;
}
#policy {
	padding: 100px 0 70px;
}
#intro {
    padding: 4em 0px;
}
#intro h2 {
	font-size: 40px;
	font-weight: 300;
	margin: 15px 0 15px 0;
}
.intro-pic {
	margin-top: 20px;
}
.btn-section {
	padding-top: 20px;
}
#feature {
	padding-top: 15px;
	background: $wht;
}
#feature h2 {
	margin: 15px 0 15px 0;
	font-size: 40px;
	font-weight: 300;
}
#feature .feature-title p {
	font-size: 18px;
}
.row-feat {
	padding-bottom: 50px;
}
.feat-list {
	margin-top: 40px;
}
.feat-list i {
	font-size: 300px;
	color: $primary;
	position: relative;
	opacity: 0.6;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
    
    @media (max-width: 1199px) {
        font-size: 150px;
        text-align: center !important;
    }

    @media (max-width: 1024px) {
        font-size: 200px;
        text-align: center !important;
    }

    @media (max-width: 767px) {
        font-size: 225px;
        text-align: center !important;
    }

}
.feat-list:hover i {
	color: $secondary;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
#feature .inner {
	float: left;
	display: inline-block;
    margin-top: 25px;
}
#feature-2 {
	padding: 100px 0 100px;
}
#feature-2 h2 {
	font-size: 3em;
	font-weight: 300;
	margin: 15px 0 15px 0;
}
.feature-2-pic {
	margin-top: 20px;
}
.subscribe {
	color: #fff;
}
.subscribe p {
	margin: 30px auto 30px;
}
.subscribe-form {
	max-width: 400px;
	margin: 50px auto 150px;
	text-align: center;
	overflow: hidden;
}
.subscribe-form form {
	position: relative;
}
.subscribe-form input {
	max-width: 85%;
	position: relative;
	padding: 5px 25px;
}
.subscribe-form .form-control {
	border-radius: 4px 0 0 4px;
	border: none;
	background-color: rgba(255, 255, 255, 0.6);
	color: #333;
	font-size: 1.2em;
	height: 55px;
}
.subscribe-form button {
	border-radius: 0 4px 4px 0;
	background-color: #201a1c;
	color: #ffffff;
	font-size: 1em;
	line-height: 52px;
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 0 30px;
	margin: 0;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
.subscribe-form .btn {
	height: 55px;
}
.subscribe-form .btn:hover {
	background-color: #1f96e0;
	color: #fff;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
.subscribe-form .form-control::-webkit-input-placeholder {
	color: #333;
}
.subscribe-form .form-control:-moz-placeholder {
	color: #333;
}
.subscribe-form .form-control::-moz-placeholder {
	color: #333;
}
.subscribe-form .form-control:-ms-input-placeholder {
	color: #333;
}
#package {
	padding-top: 100px;
}
.title-line {
    width: 100px;
    height: 3px;
    margin: 0 auto;
    background: #201a1c;
}
.price-box {
	border: solid 1px #d1d1d1;
}
.package-option {
	padding: 50px 0 100px;
}
.package-option ul {
	padding: 0;
}
.price-heading h3 {
	margin-top: 0;
}
.price-heading i {
	color: #d1d1d1;
	font-size: 75px;
	margin-top: 20px;
}
.price-group {
	padding: 30px 0 10px;
}
.price-group .dollar {
	font-size: 20px;
	position: relative;
	bottom: 48px;
}
.price-group .price {
	color: #201a1c;
	font-size: 90px;
	font-weight: 500;
}
.price-group .time {
	font-size: 18px;
}
.price-feature li {
	margin-left: 30px;
	margin-right: 30px;
	list-style: none;
	border-bottom: solid 1px #d1d1d1;
	line-height: 40px;
}
.btn-price {
	margin: 5px 0 15px;
	font-size: 17px;
	padding: 7px 35px;
	height: 40px;
	background: #201a1c;
	transition: all 0.4s;
	color: white;
	border-radius: 4px;
}
.btn-price:hover {
	background: #1f96e0;
	color: white;
}
#client {
	background: #f6f6f6;
	padding: 70px 0 70px;
}
#client img {
	max-height: 50px;
	margin: 0 20px;
	opacity: 1;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
}
#client img:hover {
	opacity: 0.7;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
}
#testi {
	padding: 25px 25px;
}
.testi-item {
	display: block;
	width: 100%;
	height: auto;
	position: relative;
	margin-top: 30px;
}
.testi-item .box {
	margin-right: 15px;
	margin-left: 15px;
}
.testi-item .box .message {
	padding: 20px;
	font-style: italic;
	line-height: 30px;
	font-weight: 300;
	font-size: 20px;
}
.testi-item .client-pic img {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	max-width: 100%;
    box-shadow: 0px 0px 20px #201a1c;
}
.testi-item .client-info .client-name {
	margin-top: 10px;
	font-size: 16px;
}
.testi-item .client-info .company {
	font-style: italic;
	color: #201a1c;
}
.owl-theme .owl-controls .owl-page span{
	background: #201a1c;
}
.video-header {
	margin: 40px 0 30px;
}
.video-embed {
	position: relative;
	padding-top: 56.25%;
	height: 0;
	background-color: #000000;
	-webkit-box-shadow: 0 5px 15px rgba(0,0,0,0.2);
	box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}
.video-embed iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.action {
	color: #fff;
}
.action h2 {
	margin-top: 20px;
	font-size: 40px;
	font-weight: 300;
}


.action img {width: 100%;
             display: block;
             margin: 0px auto;
             max-width: 550px;}

.download-cta {
	padding: 50px 0 50px;
}
#contact {
	width: 100%;
	min-height: 100%;
}
#contact  h2{
	color: white;
}
.ul-address a {
	font-weight: normal;
}
.ul-address a:hover {
	color: white;
}
.ul-address li {
	padding-right: 20px;
	margin-bottom: 8px;
	list-style: none;
	color: white;
}
.ul-address i {
	margin-left: 15px;
	position: absolute;
	left: 0;
	color: #201a1c;
	font-size: 25px;
	line-height: 30px;
}
.contact-row {
	margin: 100px 0 100px;
}
#contact-form {
	margin: 0 auto;
}
#contact-form input {
	position: relative;
	padding: 5px 25px;
	width: 100%;
}
#contact-form textarea {
	position: relative;
	padding: 10px 25px;
	width: 100%;
	height: 120px !important;
}
#contact-form .form-control {
	border-radius: 0;
	border: solid 1px #dadada;
	background-color: #fff;
	color: #333;
	font-size: 1.2em;
	height: 55px;
}
#contact-form .btn {
	height: 55px;
	width: 100%;
	background-color: #201a1c;
	color: #ffffff;
	font-size: 17px !important;
	line-height: 18px;
	padding: 0 33px;
	border: none;
	border-radius: 0;
	margin: 0;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
#contact-form .btn:hover {
	background-color: #1f96e0;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
#footer {
	background: #fff;
	padding: 50px 0 50px;
}
.social ul {
	padding: 0;
	list-style: none;
}
.social li {
	display: inline-block;
	padding-right: .3em;
	padding-bottom: .3em;
}
.social li a {
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #201a1c;
	border-radius: 50%;
	background: #fff;
	border: solid 1px #201a1c;
	-webkit-transition: all .8s ease;
	transition: all .8s ease;
}
.social li a:hover {
	color: #fff;
	background: #201a1c;
}
#shortcode-basic {
	padding: 70px 0 10px;
}
#shortcode-button {
	padding: 50px 0 10px;
	width: 100%;
	min-height: 100%;
}
#shortcode-button .form-horizontal {
	padding-left: 20px;
	padding-right: 20px;
}
#shortcode-icon {
	padding: 70px 0 10px;
}
#shortcode-alert {
	padding: 70px 0 10px;
}
#shortcode-table {
	padding: 70px 0 10px;
}
#shortcode-column {
	padding: 50px 0 10px;
}
.social-icons i {
	color: #201a1c;
	font-size: 30px;
	line-height: 40px
}
#shortcode-alert {
	padding: 70px 0 30px;
}
.alert {
	border: none;
	border-radius: 0;
	position: relative;
	font-size: 17px;
	line-height: 22px;
	padding: 16px;
	padding-left: 60px;
}
.alert i {
	font-size: 28px;
	position: absolute;
	left: 15px;
	top: 13px;
}
.icon-demo i {
	font-size: 70px;
	margin-right: 20px;
}
.table > thead > tr > th {
	background: #201a1c;
	color: #fff;
	border-bottom: 0;
	text-transform: uppercase;
}
.table > thead > tr > th,
.table > tbody > tr > td {
	font-size: 15px;
	font-weight: 300;
	padding: 20px 20px;
}
.table-bg > tbody > tr:nth-child(odd) > td,
.table-bg > tbody > tr:nth-child(odd) > th {
	background: #fff;
}
.table-bg > tbody > tr:nth-child(even) > td,
.table-bg > tbody > tr:nth-child(even) > th {
	background: #f6f6f6;
}
.scrollToTop{
	width: 40px;
	height: 40px;
	padding: 5px;
	font-size: 30px;
	text-align: center;
	background: rgba(0, 0, 0, 0.2);
	color: white;
	position: fixed;
	bottom: 20px;
	right: 20px;
	border-radius: 50%;
	display: none;
}
.scrollToTop:hover{
	color: #201a1c;
}
.control-group .controls {
	overflow-x: hidden;
}
/* iPads (portrait) ----------- */
@media only screen
and (min-width : 768px)
and (max-width : 1024px)
and (orientation : portrait) {
	.head-btn {
		margin: 50px 0 100px;
	}
	.signup-header {
		margin: 50px 0 100px;
	}
	.price-feature li {
		margin-left: 15px;
		margin-right: 15px;
	}
	.btn-price {
		padding: 7px 25px;
	}
	#intro {
		padding: 30px 0 50px;
	}
	#policy {
		padding: 30px 0 50px;
	}
	#intro h2, #feature-2 h2 {
		text-align: center;
	}
	#intro p, #feature-2 p {
		text-align: center;
	}
	.btn-section {
		text-align: center;
	}
	#feature {
		padding-top: 75px;
	}
	.feature-img {
		margin-top: 0;
	}
	.row-feat {
		padding-top: 10px;
		padding-bottom: 40px;
	}
	#feature-2 {
		padding: 80px 0 80px;
	}
	.feature-2-pic {
		margin-top: 40px;
	}
	#client img {
		margin: 10px 0 10px;
		max-height: 40px;
	}
	.video-header {
		margin: 50px 0 30px;
	}
	#bottom-action {
		text-align: center;
	}
	.button-demo li {
		padding-bottom: 50px;
	}
}

/* iPads (landscape) ----------- */
@media only screen
and (min-width : 768px)
and (max-width : 1024px)
and (orientation : landscape) {
	.logo {
		margin: 350px 0 30px 0;
	}
	.landing h1{
		margin: 60px 0 60px 0;
	}
	.landing-text {
		margin: 60px 0 40px 0;
	}
	.signup-header {
		margin-top: 250px;
	}
	.intro-pic {
		margin-top: 100px;
	}
	.feature-img {
		margin-top: 30px;
	}
	.feat-list {
		margin-top: 10px;
	}
	.feat-list p {
		font-size: 15px;
	}
	.feature-2-pic {
		margin-top: 50px;
	}
	#client img {
		max-height: 45px;
	}
	.video-header {
		margin: 50px 0 30px;
	}
}
@media (max-width:991px){
  .download-info {
    margin-top: 50px;
  }
}
/* smart-phone ----------- */
@media (max-width: 767px) {
	.logo {
		margin: 40px 0 30px 0;
	}
	.head-btn {
		margin: 30px 0 80px;
	}
	.signup-header {
		margin: 70px 0 80px;
	}
	#intro {
		padding: 70px 0 70px;
	}
	#policy {
		padding: 70px 0 70px;
	}
	#intro h2, #feature-2 h2 {
		font-weight: 150;
		text-align: center;
	}
	#intro p, #feature-2 p {
		text-align: center;
	}
	.btn-section {
		text-align: center;
	}
	#feature {
		padding-top: 50px;
	}
	.row-feat {
		padding-top: 10px;
		padding-bottom: 40px;
	}
	.feat-list i {
		padding-left: 40px;
	}
	#feature-2 {
		padding: 40px 0 50px;
	}
	.feature-2-pic {
		margin-top: 50px;
	}
	#package {
		padding-top: 80px;
	}
	.package-option {
		padding: 50px 0 50px;
	}
	.price-box {
		margin-bottom: 30px;
	}
	#client img {
		margin: 10px 0 10px;
	}
	.contact-left {
		margin-bottom: 50px;
	}
	.video-header {
		margin: 50px 0 30px;
	}
	#bottom-action {
		text-align: center;
	}
}
/* iPhone 6Plus (landscape) ----------- */
@media (max-width: 736px) {
	.feature-img {
		margin-top: 0;
	}

}

/* iPhone 6 (landscape) ----------- */
@media (max-width: 667px) {

	.logo {
		margin: 30px 0 30px 0;
	}
	.landing h1{
		font-size: 50px;
		margin: 10px 0 25px 0;
	}
	.landing-text {
		margin-top: 20px;
	}
	.subscribe p {
		margin: 25px auto 15px;
	}
	.subscribe-form {
		margin: 20px auto 100px;
	}
	.action h2 {
    font-size: 1.5em;
		margin-top: 70px;
	}
  p {
    font-size: 1em;
  }
	.download-cta {
		padding: 50px 0 100px;
	}
}

/* iPhone 5 (landscape) ----------- */
@media (max-width: 568px){
	.logo {
		margin: 15px 0 15px 0;
	}
	.landing h1{
		font-size: 40px;
		margin: 20px 0 10px 0;
	}
	.landing-text p {
		font-size: 16px !important;
	}
	#intro {
		padding-top: 50px;
	}
	#policy {
		padding-top: 50px;
	}
	#feature {
		padding-top: 50px;
	}
	.feat-list i {
		padding-left: 20px;
	}
	#feature-2 {
		padding: 50px 0 70px;
	}
	#screenshot {
		padding-top: 70px;
	}
	.screenshots {
		padding-top: 30px;
		padding-bottom: 70px;
	}
	#testi {
		padding: 50px 0 0px;
	}
	.testi-item .box .message {
		line-height: 25px;
		font-size: 18px;
	}
	.action h2 {
		margin: 50px 0 20px;
	}
	.download-text p {
		line-height: 1.5;
	}
}

/* iPhone 4 (landscape) ----------- */
@media (max-width: 480px) {
	.feat-list i {
		padding-left: 5px;
	}
  .download-info {
    margin-top:35px;
  }
	.button-demo li {
		padding-bottom: 50px;
	}
}

/* smart phone width: 360px ----------- */
@media (max-width: 360px) {
	#intro h2, #feature h2, #feature-2 h2 {
		font-size: 30px;
	}
	.feat-list i {
		padding-left: 0;
	}
	#feature .inner {
		padding: 0px 15px;
	}
	.video-header {
		margin: 30px 0 30px;
	}
}

/* iPhone 4/5 (portrait) ----------- */
@media (max-width: 320px) {
	.logo {
		margin: 30px 0 20px 0;
	}
	.landing h1{
		margin: 20px 0 35px 0;
	}
	.btn-default {
		font-size: 16px;
		margin: 30px 0 10px;
		height: 35px;
		border-radius: 80px;
	}
	.btn-primary {
		font-size: 16px;
		margin: 30px 0 10px;
		margin-right: 15px;
		padding: 15px 30px;
		height: 35px;
		border-radius: 80px;
	}
	.subscribe-form {
		margin: 50px auto 110px;
	}
	.subscribe-form input {
		padding: 5px 15px;
	}
	.subscribe-form button {
		padding: 0 15px;
	}
	.action h2 {
		margin: 70px 0 20px;
	}
	.table > thead > tr > th,
	.table > tbody > tr > td {
		padding: 20px 10px;
	}
}

@media (max-width:402px){
    .site-name img{
    width: 200px;
    margin-top: 7px;
    }
}

.banner {
	background: url(../images/banner.jpg) no-repeat;
	background-size: cover;
	height: 300px;
	padding: 100px 0;
}
.banner h1 {
	color: white;
	font-weight: 700;
}
@media (max-width: 1024px) {
	.banner {
		background-attachment: initial !important;
	}
}
@media (max-width: 1024px) {
	.logo2 {
		max-width: 150px;
		margin-top: 20px;
	}
}
.logo2 {
  max-width: 200px;
  margin-top: 7px;
}

.parallax {
    background-attachment: fixed;
}

#terms,#privacy {
	hr {
		display: none;
	}
	
}